<template>
  <div class="inner-step">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-10">
          <div class="row">
            <div class="col-12" :class="{ loading }">
              <div class="text-center">
                <img :src="image" alt="success" class="success-image" />
              </div>
              <div class="text-center" v-html="$t('Success.text')" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <footer class="footer-booking pt-3 pt-lg-4">
                <div class="row d-flex justify-content-between">
                  <div class="col-md-6">
                    <router-link
                      :to="{ name: 'ChooseGame', query: $route.query }"
                      class="btn is-danger"
                    >
                      {{ $t('Success.btn1') }}
                    </router-link>
                  </div>
                  <div class="mt-3 mt-md-0 col-md-6">
                    <a href="https://ardi.one/" class="btn is-accent">
                      {{ $t('Success.btn2') }}
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from '../assets/images/Success.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'BookingThanks',
  props: {
    stripeCheckoutSessionId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'booked_game',
      'booking_details',
      'booking_amount',
      'discount',
    ]),
  },
  data() {
    return {
      loading: false,
      status: 'Please wait...',
      loadDataInterval: null,
      image,
    };
  },

  methods: {
    track() {
      // this.$gtm.trackEvent({
      //   event: 'purchase order approved',
      // });
      // this.$gtm.trackEvent({
      //   event: 'conversion',
      //   send_to: 'AW-663595492/Vx7iCPic-NMBEOTTtrwC',
      //   value: this.booking_amount.amount,
      //   currency: this.booking_amount.currency,
      //   transaction_id: this.stripeCheckoutSessionId || 'faktura',
      // });
      // this.$gtm.trackEvent({
      //   event: 'purchase',
      //   transaction_id: this.stripeCheckoutSessionId || 'faktura',
      // });
    },

    loadData() {
      this.loading = true;
      this.$http
        .get('booking/payment/stripe/' + this.stripeCheckoutSessionId)
        .then((response) => {
          this.loading = false;
          if (response.data.status !== null) {
            this.status = response.data.status;
            clearInterval(this.loadDataInterval);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.$emit('onChangeStep', 3);
    if (this.stripeCheckoutSessionId) {
      // this.$gtm.trackEvent({
      //   event: 'purchase_card',
      // });
      this.loadData();
      this.loadDataInterval = setInterval(() => {
        this.loadData();
      }, 3000);
    } else {
      // this.$gtm.trackEvent({
      //   event: 'purchase_invoice',
      // });
    }
  },
};
</script>

<style scoped lang="scss">
@import '../styles/utilities/utilities';

.success-image {
  max-width: 120px;
  width: 100%;
  height: auto;
  display: inline-block;
  margin: 10px 0;
  @include media(md) {
    max-width: 320px;
    margin: 40px 0;
  }
}

.inner-step {
  background-image: url('../assets/images/forms-4.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 200px;
  @include media(md) {
    background-size: 400px;
  }
}
</style>
